import { createApp } from 'vue'
import App from './Carousel.vue'
import VueCarousel from 'vue3-carousel';

const mountEls = document.querySelectorAll(".carousel") ?? [];

mountEls.forEach(mountEl => {
    const app = createApp(App, { ...mountEl.dataset })
        .mount('#' + mountEl.id);    
});
