<template>
  <div>
    <Carousel :autoplay="Math.floor(Math.random() * (6000 - 3000 + 1) + 3000)" :wrap-around="true">
        <Slide v-for="slide in slideArray" :key="slide">
            <div class="carousel__item">
                <img :src="slide.image" />
            </div>
        </Slide>
    </Carousel>
    <slot>
    </slot>
  </div>
</template>

<script>
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel'

export default {
  components: {
    Carousel,
    Slide,
    Pagination,
    Navigation
  },
  computed: {
    slideArray() {
      return JSON.parse(this.slides);
    }
  },
  props: {
    slides: String
  },
}
</script>
